<template>
  <v-navigation-drawer fixed temporary v-if="showSideBar" :value="showSideBar" @input="showOrHide" right>
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title>About</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
export default {
    name:"sidebar",
    data() {
        return {
            group:''
           
        }
    },
    props: ['draw'],
    computed: {
        showSideBar(){
           return store.getters.showSideBar;
           
        }
    },
    methods: {
        showOrHide(state){
            if(!state){
               this.$store.commit('toggleSideBar');
            }
        }
    },
   
};
</script>

<style>
</style>