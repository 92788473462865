<template> 
    <div>
      <v-container>
         
         <v-row>
            <v-col lg="6">
                <v-img
                src="../assets/main-1.jpg"
                class="rounded"
                ></v-img>
            </v-col>
            <v-col lg="6" class="d-flex flex-column">
                <div class="my-auto">
                    <h1 class="text-uppercase font-italic">Agritrust</h1>
                    <p>
                        Là cổng thông tin truy xuất nguồn gốc nông sản Việt Nam.
                        <br>
                        Được tài trợ bởi "Dự án hổ trợ các doanh nghiệp nhỏ và vừa Trà Vinh"(SME Trà Vinh), phối hợp với Sở Khoa Học Công Nghệ Trà Vinh.
                        <br>
                        Với mục đích: <br>
                        <ul>
                            <li>Đẩy mạnh xã hội hóa hoạt động truy xuất nguồn gốc để phục vụ hội nhập quốc tế và nâng cao hiệu quả công tác quản lý nhà nước, đảm bảo chất lượng, tính an toàn sản phẩm, hàng hóa.</li>
                            <li>Nâng cao nhận thức của xã hội, các cơ quan, tổ chức và doanh nghiệp về truy xuất nguồn gốc thông qua việc đẩy mạnh các hoạt động tuyên truyền, phổ biến, đào tạo cho các bên liên quan</li>
                            <li>Đảm bảo công khai, minh bạch các thông tin về nguồn gốc sản phẩm, hàng hóa trên thị trường, cung cấp thông tin, kiến thức thiết yếu về nguồn gốc.</li>
                        </ul>
                    </p>
                </div>
            </v-col>
         </v-row>
          <v-row
          justify="center"
          >
              <v-col
              cols="6"
              lg="3"
              align-self="center"
              >
                <v-img
                max-width="100%"
                src="../assets/logo-sme.png"
                dark
                >
                </v-img>
              </v-col>
              <!--  -->
              <v-col
              cols="6"
              lg="3"
              align-self="center"
              >
                <v-img
                src="../assets/logo-so-khcn.png"
                >                   
                </v-img>
              </v-col>
              <!--  -->
              <v-col
              cols="6"
              lg="3"
              align-self="center"
              >
                <v-img
                src="https://congnghesoasc.com/storage/settings/October2019/Q7c65nuGiD0OnWJoBUiC.png"
                max-height="135px"
                contain
                >                   
                </v-img>
              </v-col>
          </v-row>
      </v-container>
      
    </div>  
</template>

<script>
export default {
    name:"Home",
    title:"Trang chủ",
    data () {
        return {
            
        }
    },
    methods: {
        
    }

}
</script>

<style>

</style>