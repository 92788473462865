import Vue from 'vue'
import VueRouter from 'vue-router'
import Trace from '../views/Trace.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trace/:qrcode',
    name: 'Trace',
    component: Trace
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Dashboard.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
    base: __dirname
})

export default router
