<template>
  <div class="home">
    <div class="text-center fullpage" 
    v-if="loading"> 
    <v-progress-circular
      :size="70"
      :width="7"
      color="green"
      class="mx-auto"
      indeterminate>
    </v-progress-circular>
    </div>
   
    <ThongTinSanPham :qrcode=this.$route.params.qrcode :product=this.product v-if="loading==false"></ThongTinSanPham>
  </div>
</template>

<script>
// @ is an alias to /src
import ThongTinSanPham from '@/components/ThongTinSanPham.vue'

export default {
  name: 'Trace',
  title: "Truy xuất",
  components: {
    ThongTinSanPham  
    },
  data() {
    return {
      product:null,
      loading:true,
      host:''
    }
  },
  methods: {
    
  },
  mounted() {
     this.host=this.$hostname;
    var asc=this;

    this.axios.get(asc.host+'/api/trace-code/'+this.$route.params.qrcode).then(res=>{
      
      if(res.status==200)
      {
        this.loading=false;
        asc.product=res.data;
      }
      else{console.log(res.data);}
      
    }).catch(err=>{console.log(err)})
    ;
  },
}

</script>



<style lang="scss" scoped>
  .logo{
    width: 86px;
    height: 86px;
    object-fit: contain;
  }
  .fullpage{
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    justify-content: center;

  }
</style>