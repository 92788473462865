<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" md="4">
        <!-- <v-img
          :src="require('../assets/logo_square.png')"
          class="my-3"
          contain
          height="200"
        /> -->
        <v-img
          :src="host+'/storage/'+this.product.image"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      <v-col cols="12" md="8" class="text-left">
        <v-card-title >{{this.product.name}}</v-card-title>
        <v-card-subtitle> {{this.product.gioithieu}}</v-card-subtitle>
        
       
        <v-card-text>
           <hr />
        <p class="mb-1">Thông tin</p>
        <p class="mb-1">Mã QR: {{this.$props.qrcode}}</p>
        <p class="mb-1">Ngày sản xuất: Xem trên bao bì</p>
        <p class="mb-1">Ngày hết hạn: Xem trên bao bì</p>
        <p class="mb-1">Số lượt scan: {{product.views_count}}</p>
        </v-card-text>
       
      </v-col>
    </v-row>
    <v-container>
      <v-expansion-panels popout v-model="panel" multiple>
        <!-- 0. Hướng dẫn -------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50"> Hướng dẫn sử dụng</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="dynamic-content p-lg-3" v-html="this.product.hdsd">
              
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- -------------------------------- -->
          <!--1. Thành phần -------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50"> Thành phần dinh dưỡng</v-expansion-panel-header>
          <v-expansion-panel-content  class="p-lg-3">
            <div class="dynamic-content" v-html="this.product.thanhphan"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- -------------------------------- -->
        <!--2. Giống  -------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50"> Nguồn gốc (Giống)  </v-expansion-panel-header>
          <v-expansion-panel-content  class="p-lg-3">
            <div class="dynamic-content" v-html="this.product.giong"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- -------------------------------- -->
        <!--3. Đơn vị phân phối-------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50"> Đơn vị phân phối</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" lg="6">
                <v-card-title primary-title class="mx-0 px-0">
                 {{ this.product.company.name }}
                </v-card-title>
                
                <hr />
                <p class="mb-1">
                  Địa chỉ: {{this.product.company.diachi}}
                </p>
                <p class="mb-1">
                  Điện Thoại: <a :href="'tel:'+this.product.company.dienthoai"> {{ this.product.company.dienthoai }}</a>
                </p>
                <p class="mb-1">
                  Mã số thuế: {{ this.product.company.mst }}
                </p>
                <p class="mb-1">
                  Email:
                  <a :href="'mailto:'+this.product.company.email">
                    {{ this.product.company.email }}</a
                  >
                </p>
              </v-col>
              <v-col cols="12" lg="6" class="text-center">
                <p v-html="this.product.company.gmap"></p>
              </v-col>
            </v-row>
            <!-- company info -->
              <v-expansion-panel>
                  <v-expansion-panel-header class="text-light"  color="#ababab">
                    Thông tin thêm về doanh nghiệp
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-1">
                      Qui mô sản xuất: {{ this.product.company.quimosx }}
                    </p>
                    <p class="mb-1">
                      Số lượng lao động: {{ this.product.company.soluonglaodong }}
                    </p>
                    <p v-html="product.company.thongtin"></p>
                    
                  </v-expansion-panel-content>
              </v-expansion-panel>
            <!-- end company info -->
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- -------------------------------- -->
        <!--5.Chứng nhận sp -------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50">
            Chứng nhận sản phẩm
          </v-expansion-panel-header>
          <v-expansion-panel-content>
           
            <v-row class="mx-2">
              <v-col v-for="(index, i) in cnsp" :key="i" class="text-center" sm="6" md="4" lg="2" cols="6">
                <v-img
                  :src="host+'/storage/'+index.download_link"
                   aspect-ratio="1"
                   @click="zoomIn(index)"                
                />
                {{ index.title }}                
              </v-col>
            </v-row>
            
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ----- -->
        <!--6.Qui cách đóng gói -------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50">
            Qui cách đóng gói</v-expansion-panel-header
          >
          <v-expansion-panel-content class="p-lg-3">
            <div class="dynamic-content" v-html="this.product.donggoi" ></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- -------------------------------- -->
        <!--7.Qui trình sản xuất -------------------------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-light"  color="#4CAF50">
            Qui trình sản xuất</v-expansion-panel-header
          >
          <v-expansion-panel-content  class="p-lg-3">
            <div class="dynamic-content" v-html="this.product.quitrinhsx"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- -------------------------------- -->
         <!--8. Thông tin khác -->
         <v-expansion-panel v-if="product.thongtinkhac!=null">
          <v-expansion-panel-header class="text-light"  color="#4CAF50">
            Thông tin khác</v-expansion-panel-header
          >
          <v-expansion-panel-content  class="p-lg-3">
            <div class="dynamic-content" v-html="this.product.thongtinkhac"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--------  -->
      </v-expansion-panels>
     
      
    </v-container>
    <!-- dialog image zoom chứng nhận sp -->
    <v-dialog
      v-model="dialog"
      v-on:click:outside="clearChungnhan"
      dark
    >
     
      <v-card v-if="this.chungnhan!=''">
        <v-card-title class="headline">
           {{ chungnhan.title }} 
        </v-card-title>
        <v-card-text>
            <v-img
            max-height="80vh"
            contain
                  :src="host+'/storage/'+chungnhan.download_link"                  
                />
             
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
  </v-container>
</template>

<script>
export default {
  name: "ThongTinSanPham",

  data: () => ({
    panel: [0, 1, 2, 3, 5, 6],
    testHtml:"",
    dialog: false,
    host:'',
    chungnhan:''
  }),
  props: ['qrcode','product'],
  computed: {
    cnsp(){
      return JSON.parse(this.$props.product.chungnhansp)
    }
  },
  methods: {
    zoomIn:function(cn){     
      this.dialog=true;
      this.chungnhan=cn;
    },
    clearChungnhan:function(){
      this.dialog=false;
      this.chungnhan='';
    }
  },
  mounted() {
    this.host=this.$hostname
  },
};
</script>

<style lang="scss" scoped>
hr {
  display: inline-block;
  background-color: #8d8d8d;
  width: 25%;
}
</style>
<style lang="scss">
iframe {
  max-width: 100% !important;
}
.p-3{
  padding: 1.3rem;
}
.text-light{
  color: #fff;
}
.v-card__title{
  word-break: break-word !important;
}
.dynamic-content{
  display: block;
  *{
    max-width: 100% !important;
    }
}
</style>