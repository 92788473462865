<template>
  <v-app>
    <v-app-bar app color="success" dark>
      <router-link class="d-flex align-center" to="/">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="55"
        />
        <v-img
          alt="Công Nghệ Số ASC"
          class="shrink  hidden-sm-and-down"
          contain
          min-width="150"
          :src="require('./assets/logo-name.png')"
          width="150"
        />
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="toogleSidebar" ></v-app-bar-nav-icon>
    </v-app-bar>
    <sidebar ></sidebar>
    <!-- main content -->
    <v-main>
      <transition transition="fade-transition">
        <router-view></router-view>
      </transition>      
    </v-main>
    <!-- footer -->
    <v-footer padless
    class="mt-auto "        
    >
      <v-col class="text-center">© Bản quyền: Công ty TNHH MTV <a href="https://congnghesoasc.com">Công Nghệ Số ASC</a></v-col>        
    </v-footer>
     
  </v-app>
</template>

<script>
import sidebar from "@/components/SideBar";

export default {
  name: "App",

  components: {
    sidebar,
  },

  data: () => ({
   
  }),
  methods: {
    toogleSidebar(){
      this.$store.commit('toggleSideBar');
    },
  },
};
</script>
