import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store= new Vuex.Store({
  state: {
    showSideBar:false
  },
  mutations: {
    toggleSideBar(state){
      state.showSideBar= !state.showSideBar;
    }
  },
  getters: {
    showSideBar:(state)=>{return state.showSideBar}
  },

  actions: {
  },
  modules: {
  }
})

export default store